.app-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 10px;
}

.toolbar-btn {
  width: 30px;
}

.editor-p {}

.form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  backdrop-filter: blur(2px);
}

@media print {
  .rcs-positioning {
    display: none;
  }

  .rcs-custom-scroll .rcs-inner-container {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
}